<template>
    <div class="example" :style="{padding: '10px'}">
      <quill-editor
        class="editor"
        :style="{height: '100%'}"
        ref="myTextEditor"
        :disabled="false"
        :value="editor_content"
        :options="editorOption"
        @change="onEditorChange"
        @blur="onEditorBlur($event)"
        @focus="onEditorFocus($event)"
        @ready="onEditorReady($event)"
      />
      <div :style="{display: 'grid', paddingTop: '30px'}">
        <b-form-checkbox
      v-model="comment_allow"
    >
      댓글 허용
    </b-form-checkbox>
    <!-- <b-form-checkbox
      v-model="highlight"
    >
      꽃 달기
    </b-form-checkbox> -->
      </div>
      <div :style="{display: 'flex', justifyContent: 'flex-end'}">
      <b-button :style="{marginTop: '20px'}" variant="outline-primary" class="text-primary" @click="onSubmit()">저장</b-button>
    </div>
    </div>
  </template>
<script>
import hljs from 'highlight.js'
import { quillEditor, Quill } from 'vue-quill-editor'
import debounce from 'lodash/debounce'
import 'highlight.js/styles/tomorrow.css'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import ImageResize from 'quill-image-resize-vue'

import posted from '../../../services/dashboard/posted'
import { mapActions, mapGetters } from 'vuex'
import Decimal from 'decimal.js'
Quill.register('modules/ImageResize', ImageResize)

export default {
  name: 'SmartEditor',
  components: {
    quillEditor
  },
  props: {
    category: { type: String },
    title: { type: String },
    content: { type: String },
    comment_allow: { type: Boolean },
    highlight: { type: Boolean }
  },
  data () {
    return {
      lock: false,
      editor_content: '',
      editorOption: {
        placeholder: '',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // <strong>, <em>, <u>, <s>
            [{ header: 1 }, { header: 2 }], // <h1>, <h2>
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ indent: '-1' }, { indent: '+1' }], // class제어
            [{ direction: 'rtl' }], // class 제어
            [{ size: ['small', false, 'large', 'huge'] }], // class 제어 - html로 되도록 확인
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // <h1>, <h2>, <h3>, <h4>, <h5>, <h6>, normal
            [{ font: [] }], // 글꼴 class로 제어
            [{ color: [] }, { background: [] }], // style="color: rgb(230, 0, 0);", style="background-color: rgb(230, 0, 0);"
            [{ align: [] }], // class
            ['link', 'image', 'video']
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value
          },
          ImageResize: {
            modules: ['Resize', 'DisplaySize', 'Toolbar']
            // parchment: Quill.import('parchment')
          }
        }
      }
    }
  },
  mounted () {
    this.editor_content = this.content
  },
  methods: {
    ...mapActions({
      updateCoin: 'Auth/doCheering'
    }),
    onEditorChange: debounce(function (value) {
      this.editor_content = value.html
    }, 466),
    onEditorBlur (editor) {
      // // console.log('editor blur!', editor)
    },
    onEditorFocus (editor) {
      // // console.log('editor focus!', editor)
    },
    onEditorReady (editor) {
      // // console.log('editor ready!', editor)
    },
    onSubmit () {
      if (this.$route.name === 'dashboard.edit') {
        this.edit()
      } else {
        this.save()
      }
    },
    save () {
      if (this.lock) {
        alert('잠시만 기다려주세요.')
        return
      }
      if (this.category === '') {
        alert('카테고리를 선택해주세요.')
        return
      }
      if (this.title === '') {
        alert('제목을 입력해주세요.')
        return
      }
      if (this.editor_content === '' || this.editor_content === undefined) {
        alert('본문을 입력해주세요.')
        return
      }
      // const check = this.checkHighlight()
      // if (!check) {
      //   return
      // }
      this.lock = true
      posted.post(this.category, this.title, this.editor_content, this.comment_allow, false).then(res => {
        this.lock = false
        if (res.data.resultCode === 'false' || !res.data.resultCode) {
          if (res.data.message === 'NoPermission') {
            alert('글 작성 권한이 없습니다.')
            return
          }
          if (res.data.message === 'over') {
            alert('일일 TIPS 획득량을 초과했습니다.')
            return
          }
          alert('서버 오류')
          return
        }
        let stop = false
        let itemCategory = ''

        for (let i = 0; i < this.categoryState.length; i++) {
          const list = this.categoryState[i].subcategory
          for (let j = 0; j < list.length; j++) {
            if (this.category === list[j].category_name) {
              itemCategory = this.categoryState[i].category_name
              stop = true
              break
            }
          }
          if (stop) {
            break
          }
        }
        // const reward = res.
        const ownCoin = new Decimal(this.userState.coin)
        // const reward = new Decimal(this.consumState.write)
        const reward = res.data.get_coin
        this.updateCoin(Decimal.add(ownCoin, reward))
        alert(`글이 등록되었습니다.\n코인이 채굴되어 ${reward} TIPS가 지급되었습니다`)
        this.$router.replace({ name: 'dashboard.detail', params: { item: itemCategory, category: this.category, pk: res.data.pk } })
      })
    },

    edit () {
      if (this.lock) {
        alert('잠시만 기다려주세요.')
        return
      }
      if (this.category === '') {
        alert('카테고리를 선택해주세요.')
        return
      }
      if (this.title === '') {
        alert('제목을 입력해주세요.')
        return
      }
      if (this.editor_content === '') {
        alert('본문을 입력해주세요.')
        return
      }
      // const check = this.checkHighlight()
      // if (!check) {
      //   return
      // }
      const pk = this.$route.params.pk
      this.lock = true
      posted.patch(pk, this.category, this.title, this.editor_content, this.comment_allow, this.highlight)
        .then(res => {
          this.lock = false
          if (res.data.resultCode === 'false') {
            alert('서버 오류')
          } else {
            this.$router.go(-1)
            // this.$router.replace({ name: 'dashboard.detail', params: { pk: pk } })
          }
        })
    },
    checkHighlight () {
      if (this.highlight) {
        const use = 1
        const coin = new Decimal(this.userState.coin)
        if (coin < new Decimal(use)) {
          alert(`꽃 달기 할시, ${use}코인이 필요합니다.`)
          return false
        }
        const ownCoin = new Decimal(this.userState.coin)
        const cheeringCoin = new Decimal(use)
        this.updateCoin(ownCoin - cheeringCoin)
        return true
      }
      return true
    }
  },
  computed: {
    ...mapGetters({
      userState: 'Auth/userState',
      categoryState: 'Category/categoryState',
      consumState: 'Category/consumState'
    }),
    editor () {
      return this.$refs.myTextEditor.quill
    },
    contentCode () {
      return hljs.highlightAuto(this.editor_content).value
    }
  }
}
</script>
<style>
.ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 0px;
    height: 100%;
    min-height: 350px;
}
.ql-editor{
  height: 100%;
  min-height: 350px;
}
</style>
